<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Add Role</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <div class="col-12 text-center">
                <p class="color-highlight mb-0"></p>
            </div>

            <Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">

                <form-control class="validate-field" :class="{'is-invalid' : errors.name}">
                    <Field as="input" type="text" name="name" v-model="role.name" class="form-control" id="name" placeholder="Role Name"></Field>
                    <label for="name" class="color-primary-dark font-13 font-600">Role Name</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.name}}
                    </template>
                </form-control>

                <div class="d-flex justify-content-center mt-4">
                    <button class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s">
                        Create
                    </button>
                </div>
            </Form>
        </div>
    </div>
</div>
<app-footer />
import roleService from "../../services";

export default {
    data() {
        return {
            searchedResultShow: false,
            roles: {},
            search: "",
        }
    },
    mounted: function mounted() {
        this.getRole();
    },
    methods: {
        getRole() {
            roleService.getRoles({"search": this.search}).then(response => {
                this.roles = response.data.data
            });
        },
        filterUpdate() {
            this.getRole();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.search = "";
            this.getRole();
            this.searchedResultShow = false;
        },
    }
}
import roleService from "../../services";

export default {
    data() {
        return {
            role: {
                permissions: [],
                anyPartnerPermission: false,
                anyPartnerTypePermission: false,
                anyPartnerAdminPermission: false,
                anyClusterAdminPermission: false,
                anyCommissionPermission: false,
                anyRolePermission: false,
                anyPassbookPermission: false,
                anyRewardPermission: false,
                anyTicketPermission: false,
                anyCustomAttributePermission: false
            },
            role_config_data: []
        }
    },
    mounted() {
        roleService.getRole(this.$route.params.id).then(response => {
            this.role = response.data.data;
            this.role.permissions = response.data.data.permissions.map(function (object) { return object[ 'name' ]; });
            this.anyPartnerPermission = this.findCommonElements(
                [ 'partner_read', 'partner_create', 'partner_change_approve_reject_status', 'partner_change_commission_payment_status', 'partner_update_personal_detail', 'partner_update_business_detail' ]
                , this.role.permissions);
            this.anyPartnerTypePermission = this.findCommonElements(
                [ 'partner_type_read', 'partner_type_create', 'partner_type_update' ]
                , this.role.permissions);
            this.anyPartnerAdminPermission = this.findCommonElements(
                [ 'partner_admin_read', 'partner_admin_create', 'partner_admin_update' ]
                , this.role.permissions);
            this.anyClusterAdminPermission = this.findCommonElements(
                [ 'cluster_admin_read', 'cluster_admin_create', 'cluster_admin_update' ]
                , this.role.permissions);
            this.anyCommissionPermission = this.findCommonElements(
                [ 'commission_read', 'commission_disable' ]
                , this.role.permissions);
            this.anyRolePermission = this.findCommonElements(
                [ 'role_read', 'role_create', 'role_update' ]
                , this.role.permissions);
            this.anyPassbookPermission = this.findCommonElements(
                [ 'passbook_statement_read', 'passbook_bonus_create', 'passbook_penalty_create' ]
                , this.role.permissions);
            this.anyRewardPermission = this.findCommonElements(
                [ 'reward_read', 'reward_create' ]
                , this.role.permissions);
            this.anyTicketPermission = this.findCommonElements(
                [ 'ticket_read', 'ticket_create', 'ticket_comment_create', 'ticket_payment_request_update', 'ticket_kyc_update', 'ticket_issue_update', 'ticket_bank_detail_update', 'ticket_profile_detail_update' ]
                , this.role.permissions);
            this.anyCustomAttributePermission = this.findCommonElements(
                [ 'custom_attribute_read', 'custom_attribute_create', 'custom_attribute_update' ]
                , this.role.permissions);


                this.role_config_data = [
                    {
                        main_role: this.anyPartnerPermission,
                        title: "Partner Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("partner_read"),
                                permission_title: "Partner List/view"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_create"),
                                permission_title: "Partner Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_change_approve_reject_status"),
                                permission_title: "Partner Approve/Reject"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_change_commission_payment_status"),
                                permission_title: "Change partner commission/payment status"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_update_personal_detail"),
                                permission_title: "Partner update Personal detail"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_update_business_detail"),
                                permission_title: "Partner update Business detail"
                            }
                        ]
                    },
                    {
                        main_role: this.anyPartnerTypePermission,
                        title: "Partner Type Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("partner_type_read"),
                                permission_title: "Partner Type List/view"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_type_create"),
                                permission_title: "Partner Type Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_type_update"),
                                permission_title: "Partner Type Update"
                            }
                        ]
                    },
                    {
                        main_role: this.anyPartnerAdminPermission,
                        title: "Partner Admin Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("partner_admin_read"),
                                permission_title: "Partner Admin List/view"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_admin_create"),
                                permission_title: "Partner Admin Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("partner_admin_update"),
                                permission_title: "Partner Admin Update"
                            }
                        ]
                    },
                    {
                        main_role: this.anyClusterAdminPermission,
                        title: "Cluster Admin Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("cluster_admin_read"),
                                permission_title: "Cluster Admin List/view"
                            },
                            {
                                permission_check: this.role.permissions.includes("cluster_admin_create"),
                                permission_title: "Cluster Admin Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("cluster_admin_update"),
                                permission_title: "Cluster Admin Update"
                            }
                        ]
                    },
                    {
                        main_role: this.role.permissions.includes('customer_read'),
                        title: "Customer Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("customer_read"),
                                permission_title: "Customer List/view"
                            }
                        ]
                    },
                    {
                        main_role: this.role.permissions.includes('order_read'),
                        title: "Order Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("order_read"),
                                permission_title: "Customer List/view"
                            }
                        ]
                    },
                    {
                        main_role: this.anyCommissionPermission,
                        title: "Commission Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("commission_read"),
                                permission_title: "Commission List/view"
                            },
                            {
                                permission_check: this.role.permissions.includes("commission_disable"),
                                permission_title: "Commission Disable"
                            }
                        ]
                    },
                    {
                        main_role: this.role.permissions.includes('payment_transaction_read'),
                        title: "Payment Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("payment_transaction_read"),
                                permission_title: "Payment Transaction List/View"
                            }
                        ]
                    },
                    {
                        main_role: this.anyRolePermission,
                        title: "Role Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("role_read"),
                                permission_title: "Role List/View"
                            },
                            {
                                permission_check: this.role.permissions.includes("role_create"),
                                permission_title: "Role Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("role_update"),
                                permission_title: "Role Update"
                            }
                        ]
                    },
                    {
                        main_role: this.anyPassbookPermission,
                        title: "Passbook Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("passbook_statement_read"),
                                permission_title: "Passbook List/View"
                            },
                            {
                                permission_check: this.role.permissions.includes("passbook_bonus_create"),
                                permission_title: "Passbook Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("passbook_penalty_create"),
                                permission_title: "Passbook Update"
                            }
                        ]
                    },
                    {
                        main_role: this.anyRewardPermission,
                        title: "Reward Point Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("reward_read"),
                                permission_title: "Reward Point List/View"
                            },
                            {
                                permission_check: this.role.permissions.includes("reward_create"),
                                permission_title: "Reward Point Create"
                            }
                        ]
                    },
                    {
                        main_role: this.anyTicketPermission,
                        title: "Ticket Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("ticket_read"),
                                permission_title: "Ticket List/View"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_create"),
                                permission_title: "Ticket Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_comment_create"),
                                permission_title: "Ticket Comment Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_payment_request_update"),
                                permission_title: "Ticket Payment Request Approve/Update"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_kyc_update"),
                                permission_title: "Ticket KYC Approve/Update"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_issue_update"),
                                permission_title: "Ticket Issue Update"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_bank_detail_update"),
                                permission_title: "Ticket Bank Detail Approve/Update"
                            },
                            {
                                permission_check: this.role.permissions.includes("ticket_profile_detail_update"),
                                permission_title: "Ticket Profile Detail Approve/Update"
                            }
                        ]
                    },
                    {
                        main_role: this.anyCustomAttributePermission,
                        title: "Custom Attribute Permission",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("custom_attribute_read"),
                                permission_title: "Custom Attribute List/View"
                            },
                            {
                                permission_check: this.role.permissions.includes("custom_attribute_create"),
                                permission_title: "Custom Attribute Create"
                            },
                            {
                                permission_check: this.role.permissions.includes("custom_attribute_update"),
                                permission_title: "Custom Attribute Update"
                            }
                        ]
                    },
                    {
                        main_role: this.role.permissions.includes('referral_analytics_read'),
                        title: "Analytics",
                        permissions: [
                            {
                                permission_check: this.role.permissions.includes("referral_analytics_read"),
                                permission_title: "Referral Analytics"
                            }
                        ]
                    }
                ]
        });
    },
    methods: {
        findCommonElements(arr1, arr2) {
            return arr1.some(item => arr2.includes(item))
        }
    }
}
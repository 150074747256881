<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ role.name }}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link :to="{name: 'role-edit',params: {'id': role.id}}" class="header-icon header-icon-2"
        v-if="hasPermission('role_update')">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <h2 class="ms-4 mt-2 mb-3 letterSpacing font-20 font-500 primary-light-text"
        v-if="role && role.permissions && role.permissions.length > 0">
        <i class="fas fa-check-circle font-25 me-2"></i>
        Permissions
    </h2>
    <div class="card card-style" v-if="role && role.permissions && role.permissions.length > 0">
        <div class="content mb-2 mt-1">


            <!-- <div class="row mb-3">
                <div class="col-12 color-theme">{{ localDateTime(role.created_at) }}</div>
            </div>
            <div class="col-12" v-if="role.permissions && role.permissions.length > 0">
                {{ role.name }}
            </div> -->

            <div v-for="roleData in role_config_data">
                <div v-if="roleData.main_role">
                    <label class="primary-alt-light-text letterSpacing font-17 font-500 ms-2 mb-2 mt-2"
                        style="opacity: 1;background-color: transparent;">
                        {{ roleData.title }}
                    </label>
                    <div v-for="permission in roleData.permissions">
                        <div class="letterSpacing py-2 form-control font-14 font-400 mb-2  text-secondary"
                            style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);"
                            v-if="permission && permission.permission_check">
                            {{ permission.permission_title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5 text-center" v-else>
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Permission Found
        </p>
    </div>
</div>


<app-footer />
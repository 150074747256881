import api from "@/common/services/api";

export default new class {
    baseUrl = "/roles";
    
    getSelectData(data) {
        return api.instance.get("/get-select-data", {params: data});
    }

    createRole(data) {
        return api.instance.post(this.baseUrl, data);
    }

    getRoles(data) {
        return api.instance.get(this.baseUrl, {params: data});
    }

    getRole(id) {
        return api.instance.get(this.baseUrl + '/' +id);
    }

    updateRole(id, data) {
        return api.instance.patch(this.baseUrl+"/"+id, data);
    }

   
};
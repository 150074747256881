import roleService from "../../services";
import router from "@/router"

export default {
    data() {
        return {
            role: {
                name: null
            },
            selectData: {},
            formValidations: {
                name: v => !!v || "First name is required"
            },
        }
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Creating...");
            roleService.createRole(this.role).then(response => {
                this.$popper.info(response.data.message);
                this.hideLoader();
                router.push({'name': 'role-edit', 'params' : {'id' : response.data.id}});
            });
        },
    }
}
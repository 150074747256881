import roleService from "../../services";
import router from "@/router"

export default {
    data() {
        return {
            role: {
                'permissions': {}
            },
            formValidations: {

            },
            updatedRole: ''
        }
    },
    mounted() {
        this.showLoader(true);
        roleService.getRole(this.$route.params.id).then(responses => {
            this.role.id = responses.data.data.id;
            this.role.name = responses.data.data.name;
            this.updateRole = this.role.name;
            Object.values(responses.data.data.permissions).forEach(value =>
                this.role.permissions[ value.name ] = "true"
            );
            window.init_template();
            this.hideLoader();
        });
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Updating...");
            this.role.name = this.updateRole;
            roleService.updateRole(this.role.id, this.role).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                router.push({
                    'name': 'role-detail',
                    'params': {
                        'id': this.role.id
                    }
                });
            });
        },
    }
}